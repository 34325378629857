// @mui
import { useTheme } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import PostsForReview from '../sections/@dashboard/post/PostsForReview';

// ----------------------------------------------------------------------

export default function PostsInReview() {
  const theme = useTheme();

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Posts for review
        </Typography>

        <PostsForReview />
      </Container>
    </Page>
  );
}
