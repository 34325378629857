import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'axios';
import { getAuthHeader, SERVER } from '../common/apiUtils';

const initialState = {
  isFetching: false,
  ideas: [],
};

export const fetchIdeas = createAsyncThunk('idea/fetchIdeas', async (_, { rejectWithValue, dispatch }) => {
  dispatch(setFetching(true));
  try {
    const res = await axios.get(`${SERVER}/feature/get-ideas`, getAuthHeader());
    if (res?.data?.success) {
      dispatch(setIdeas(res.data.features));
      dispatch(setFetching(false));
      return res?.data?.features;
    }
    dispatch(setFetching(false));
    return rejectWithValue(res?.data?.message || 'Something went wrong');
  } catch (err) {
    dispatch(setFetching(false));
    return rejectWithValue('Something went wrong');
  }
});

// export const togglePostsStatus = createAsyncThunk(
//   'posts/toggleStatus',
//   async (payload, { rejectWithValue }) => {
//     try {
//       const res = await axios.post(`${SERVER}/post/update`, payload, getAuthHeader());
//       return res?.data;
//     } catch (err) {
//       return rejectWithValue('Something went wrong');
//     }
//   }
// );

const ideaSlice = createSlice({
  name: 'ideas',
  initialState,
  reducers: {
    setFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    setIdeas: (state, action) => {
      state.ideas = action.payload;
    },
    removeIdea: (state, action) => {
      state.ideas = state.ideas.filter((idea) => idea._id !== action.payload);
    },
  },
  extraReducers: {},
});

export const { setFetching, setIdeas, removeIdea } = ideaSlice.actions;

export default ideaSlice.reducer;
