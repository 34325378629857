import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'axios';
import { getAuthHeader, SERVER } from '../common/apiUtils';

const initialState = {
  isFetching: false,
  posts: [],
};

export const fetchPosts = createAsyncThunk('posts/fetchPosts', async (status, { rejectWithValue, dispatch }) => {
  dispatch(setFetching(true));
  try {
    const res = await axios.get(`${SERVER}/post/${status}`, getAuthHeader());
    if (res?.data?.success) {
      dispatch(setPosts(res.data.posts));
      dispatch(setFetching(false));
      return res?.data?.posts;
    }
    dispatch(setFetching(false));
    return rejectWithValue(res?.data?.message || 'Something went wrong');
  } catch (err) {
    dispatch(setFetching(false));
    return rejectWithValue('Something went wrong');
  }
});

export const togglePostsStatus = createAsyncThunk(
  'posts/toggleStatus',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${SERVER}/post/update`, payload, getAuthHeader());
      return res?.data;
    } catch (err) {
      return rejectWithValue('Something went wrong');
    }
  }
);

const postSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    setPosts: (state, action) => {
      state.posts = action.payload;
    },
    removePost: (state, action) => {
      state.posts = state.posts.filter((post) => post._id !== action.payload);
    },
  },
  extraReducers: {},
});

export const { setFetching, setPosts, removePost } = postSlice.actions;

export default postSlice.reducer;
