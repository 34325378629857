import { Box, Card, Divider, Typography } from '@mui/material';
import React from 'react';
import OrganisationId from './OrganisationId';
import OrganizationName from './OrganizationName';

export default function OrganizationSettings() {
  return (
    <Card>
      <Box sx={{ my: 2, mx: 2 }}>
        <Typography variant="h6">Your Organization</Typography>
        <Divider sx={{ mt: 1 }} />
        <OrganizationName />
        <OrganisationId />
      </Box>
    </Card>
  );
}
