/* eslint-disable no-extra-boolean-cast */
import { Box, Grid, LinearProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import NoPosts from './NoPosts';
import Postcard from './PostCard';

export default function PostsViewer({ posts, isFetching }) {
  const user = useSelector((state) => state.auth.user);

  if (isFetching) {
    return (
      <Grid container spacing={3}>
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      </Grid>
    );
  }
  
  return (
    <Grid container spacing={3}>
      {!!posts.length ? (
        posts.map((post) => (
          <Grid item xs={12} sm={4} key={post._id}>
            <Postcard {...post} showAdminActions={user.isAdmin} />
          </Grid>
        ))
      ) : (
        <NoPosts />
      )}
    </Grid>
  );
}
