import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { ACCESS_TOKEN_KEY, getAuthHeader, SERVER, setCookie } from '../common/apiUtils';

const initialState = {
  isLoggedIn: false,
  formError: '',
  formSuccess: '',
  forgetFormError: '',
  forgetFormSuccess: '',
  fetchingUsers: false,

  user: {
    photoURL: '/static/mock-images/avatars/avatar_default.jpg',
    fullname: '',
    email: '',
  },
  users: [],
};

export const loginUser = createAsyncThunk('auth/loginUser', async (payload, { rejectWithValue, _, dispatch }) => {
  try {
    const res = await axios.post(`${SERVER}/auth/signin`, payload);
    if (res?.data?.success) {
      dispatch(setAuthFormError(''));
      dispatch(setUser(res.data));
      return res?.data;
    }
    return rejectWithValue(res?.data?.message || 'Something went wrong');
  } catch (err) {
    return rejectWithValue('Something went wrong');
  }
});

export const verifyToken = createAsyncThunk('auth/loginUser', async (_, { rejectWithValue, dispatch }) => {
  try {
    const res = await axios.post(`${SERVER}/auth/verifytoken`, {}, getAuthHeader());
    if (res?.data?.success) {
      dispatch(setUser(res.data));
      return res?.data;
    }
    return rejectWithValue(res?.data?.message || 'Something went wrong');
  } catch (err) {
    return rejectWithValue('Something went wrong');
  }
});

export const registerUser = createAsyncThunk('auth/signupUser', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const data = {
      fullname: `${payload.firstName} ${payload.lastName}`,
      email: payload.email,
      password: payload.password,
    };
    if (payload.inviteid) {
      data.inviteid = payload.inviteid;
    }
    const res = await axios.post(`${SERVER}/auth/signup`, data);
    if (res?.data?.success) {
      dispatch(setAuthFormSuccess(res?.data?.message));
      if (res?.data?.payload?.jwtToken) {
        dispatch(setUser(res.data));
      }
      return res?.data;
    }
    return rejectWithValue(res?.data?.message || 'Something went wrong');
  } catch (err) {
    return rejectWithValue('Something went wrong');
  }
});
export const forgetPassword = createAsyncThunk(
  'auth/requestresetpassword',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${SERVER}/auth/requestresetpassword`, payload);
      if (res?.data?.success) {
        dispatch(setForgetFormSuccess(res?.data?.message));
        return res?.data?.message;
      }
      return rejectWithValue(res?.data?.message || 'Something went wrong');
    } catch (err) {
      return rejectWithValue('Something went wrong');
    }
  }
);

export const getUsers = createAsyncThunk('auth/getUsers', async (_, { rejectWithValue, dispatch }) => {
  dispatch(setFetchingUsers(true));
  try {
    const usersRes = await axios.get(`${SERVER}/auth/getusers`, getAuthHeader());
    const invitationsRes = await axios.get(`${SERVER}/organization/getinvitations`, getAuthHeader());
    if (usersRes?.data?.success && invitationsRes?.data?.success) {
      dispatch(setUsers([...usersRes.data.payload.users, ...invitationsRes.data.payload.invitations]));
      dispatch(setFetchingUsers(false));
      return usersRes.data;
    }
    dispatch(setFetchingUsers(false));
    return rejectWithValue(usersRes?.data?.message || 'Something went wrong');
  } catch (err) {
    dispatch(setFetchingUsers(false));
    return rejectWithValue('Something went wrong');
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setFetchingUsers: (state, action) => {
      state.fetchingUsers = action.payload;
    },
    setAuthFormError: (state, action) => {
      state.formError = action.payload;
    },
    setAuthFormSuccess: (state, action) => {
      state.formSuccess = action.payload;
    },
    setForgetFormError: (state, action) => {
      state.forgetFormError = action.payload;
    },
    setForgetFormSuccess: (state, action) => {
      state.forgetFormSuccess = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },

    cleanForm: (state) => {
      state.formError = '';
      state.formSuccess = '';
      state.forgetFormError = '';
      state.forgetFormSuccess = '';
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setUser: (state, { payload }) => {
      state.isLoggedIn = true;
      const token = payload?.payload?.jwtToken;
      setCookie(ACCESS_TOKEN_KEY, token);
      const decodedUserData = jwtDecode(token);
      state.user = { ...state.user, ...decodedUserData };
      state.org = payload?.payload?.organization || {
        headerColor: '#2065D1',
        textColor: '#ffffff',
      };
    },
    updateUsers: (state, { payload }) => {
      const alreadyAdded = state.users.findIndex((user) => user.email === payload.email) !== -1;
      if (!alreadyAdded) {
        state.users.push(payload);
      }
    },
    updateUser: (state, { payload }) => {
      const userIndex = state.users.findIndex((user) => user._id === payload._id);
      if (userIndex !== -1) {
        state.users[userIndex] = { ...state.users[userIndex], ...payload.data };
      }
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.formError = '';
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.formError = payload;
    },
    [registerUser.pending]: (state) => {
      state.formError = '';
      state.formSuccess = '';
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.formError = payload;
    },
    [forgetPassword.pending]: (state) => {
      state.forgetFormError = '';
      state.forgetFormSuccess = '';
    },
    [forgetPassword.fulfilled]: (state, { payload }) => {
      state.forgetFormSuccess = payload;
    },
    [forgetPassword.rejected]: (state, { payload }) => {
      state.forgetFormError = payload;
    },
  },
});

export const {
  setAuthFormError,
  setAuthFormSuccess,
  setForgetFormError,
  setForgetFormSuccess,
  setIsLoggedIn,
  setUser,
  cleanForm,
  updateUsers,
  setUsers,
  setFetchingUsers,
  updateUser,
} = authSlice.actions;

export default authSlice.reducer;
