import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';

import { FormikProvider, Form, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { Stack } from '@mui/material';

import axios from 'axios';
import { getAuthHeader, SERVER } from '../../../store/common/apiUtils';
import useResponsive from '../../../hooks/useResponsive';
import { updateUsers } from '../../../store/auth/authSlice';

export default function InviteDialog({ isOpen, handleClose }) {
  const smallDevice = useResponsive('down', 'md');
  const dispatch = useDispatch();
  const [formError, setFormError] = useState('');
  const [formSuccess, setFormSuccess] = useState('');
  const InviteFormSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });
  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
    },
    validationSchema: InviteFormSchema,
    onSubmit: (values, { resetForm }) => {
      setFormError('');
      setFormSuccess('');
      const data = {
        email: values.email,
        fullname: `${values.firstName} ${values.lastName}`,
      };
      axios
        .post(`${SERVER}/organization/invite`, data, getAuthHeader())
        .then((res) => {
          if (res?.data?.success) {
            setFormSuccess(res?.data?.message);
            dispatch(updateUsers(data));
            setSubmitting(false);
            resetForm();
          } else {
            setFormError(res?.data?.message || 'Something went wrong. Please retry');
            setSubmitting(false);
          }
        })
        .catch(() => {
          setFormError('Something went wrong. Please retry');
          setSubmitting(false);
        });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setSubmitting } = formik;
  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth={smallDevice}>
      <FormikProvider value={formik}>
        <DialogTitle>Invite New User</DialogTitle>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogContent sx={{ mt: -1 }}>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="First name"
                  {...getFieldProps('firstName')}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />

                <TextField
                  fullWidth
                  label="Last name"
                  {...getFieldProps('lastName')}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Stack>

              <TextField
                fullWidth
                autoComplete="username"
                type="email"
                label="Email address"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              {formError && (
                <Alert severity="error" sx={{ my: 1 }} variant="filled">
                  {formError}
                </Alert>
              )}
              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                Send invite
              </LoadingButton>
              {formSuccess && (
                <Alert severity="success" sx={{ my: 1 }} variant="filled">
                  {formSuccess}
                </Alert>
              )}
            </Stack>
          </DialogContent>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
