// material
import { Stack, Button, Divider, Typography, Link } from '@mui/material';
import { SERVER } from '../../store/common/apiUtils';
// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

export default function AuthSocial() {
  return (
    <>
      <Stack direction="row" spacing={2}>
        <Link href={`${SERVER}/auth/federated/google/${window.location.search}`} sx={{ width: '100%' }}>
          <Button fullWidth size="large" color="inherit" variant="outlined">
            <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
          </Button>
        </Link>
        <Link href={`${SERVER}/auth/federated/outlook/${window.location.search}`} sx={{ width: '100%' }}>
          <Button fullWidth size="large" color="inherit" variant="outlined">
            <img src="/static/microsoft-outlook.svg" alt="outlook" width={22} />
          </Button>
        </Link>
        <Link href={`${SERVER}/auth/federated/github/${window.location.search}`} sx={{ width: '100%' }}>
          <Button fullWidth size="large" color="inherit" variant="outlined">
           <Iconify icon="eva:github-fill" color="#000" width={22} height={22} />
          </Button>
        </Link>
      </Stack>

      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          OR
        </Typography>
      </Divider>
    </>
  );
}
