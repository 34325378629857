import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import JoinOrgForm from './JoinOrgForm';
import CreateOrgForm from './CreateOrgForm';

export default function OrgMaker() {
  const [isOpen, setIsOpen] = useState(false);
  const [hasInviteId, setHasInviteId] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const handleClose = (e, reason) => {
    if (reason && reason === 'backdropClick') return;
    setIsOpen(false);
  };

  const toggleForm = () => {
    setHasInviteId(!hasInviteId);
  };

  useEffect(() => {
    if (!user.organizationId) {
      setIsOpen(true);
    }
  }, [user]);

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Create/Join Organization</DialogTitle>
      <DialogContent sx={{ mt: -1 }}>
        <DialogContentText sx={{ mb: 2 }}>
          Looks like you are not a part of any organization. To proceed please create or join an organization.
        </DialogContentText>
        {hasInviteId ? <JoinOrgForm handleClose={handleClose} /> : <CreateOrgForm handleClose={handleClose} />}
        <Divider sx={{ my: 3 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            OR
          </Typography>
        </Divider>
        <DialogContentText sx={{ mb: 2 }}>
          {!hasInviteId ? (
            <>
              Already have an invitation id?{' '}
              <Button size="small" variant="text" onClick={toggleForm}>
                CLICK HERE
              </Button>{' '}
              to enter
            </>
          ) : (
            <>
              No invitation id?{' '}
              <Button size="small" variant="text" onClick={toggleForm}>
                CLICK HERE
              </Button>{' '}
              to create new organization
            </>
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
