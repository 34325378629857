import { Card, Divider, Grid, LinearProgress, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchIdeas } from '../../../store/ideas/ideaSlice';

export default function IdeasList() {
  const dispatch = useDispatch();
  const { isFetching, ideas } = useSelector((state) => state.ideas);
  useEffect(() => {
    dispatch(fetchIdeas());
  }, []);
  return (
    <>
      {isFetching && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      {!!ideas.length && (
        <>
          {ideas.map((idea) => (
            <Card key={idea._id} sx={{ my: 2 }}>
              <Box sx={{ my: 2, mx: 2 }}>
                <Typography variant="h6">{idea.title}</Typography>
                <Divider sx={{ mt: 1 }} />
                <Box sx={{ my: 2 }} flexDirection={'column'} display="flex">
                  <Typography gutterBottom variant="subtitle" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                    IDEA BY: {idea?.email}
                  </Typography>
                  <Typography gutterBottom variant="body" sx={{ whiteSpace: 'pre-line'}}>
                    {idea.description}
                  </Typography>
                </Box>
              </Box>
            </Card>
          ))}
        </>
      )}
      {!ideas.length && !isFetching && (
        <Card sx={{ width: '100%', textAlign: 'center' }}>
          <Box sx={{ mt: 2, mb: -5 }}>
            <Typography variant="subtitle" sx={{ color: 'text.secondary' }}>
              I looked everywhere but couldn't find any ideas.
            </Typography>
          </Box>
          <Box
            component="img"
            src="/static/illustrations/illustration_nodata.svg"
            sx={{ height: 500, mx: 'auto', my: { xs: 5, sm: 10 } }}
          />
        </Card>
      )}
    </>
  );
}
