import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Link } from '@mui/material';
// components
import Page from '../../../components/Page';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  //   justifyContent: 'center',
  flexDirection: 'column',
}));

// ----------------------------------------------------------------------

export default function NoPosts() {
  return (
    <Container>
      <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Typography variant="h3" paragraph>
          Sorry, no posts yet
        </Typography>

        <Typography sx={{ color: 'text.secondary' }}>
          Create and publish posts via{' '}
          <Link underline="none" component={RouterLink} to="/dashboard/create-post">
            Create Post
          </Link>{' '}
          and they will appear here.
        </Typography>

        <Box
          component="img"
          src="/static/illustrations/illustration_nodata.svg"
          sx={{ height: 500, mx: 'auto', my: { xs: 5, sm: 10 } }}
        />
      </ContentStyle>
    </Container>
  );
}
