import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';

const StyledGenerateApiKeyBox = styled('div')({
  backgroundColor: '#ececec',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  padding: ' 0 16px',
  borderRadius: '4px',
  fontFamily: 'monospace',
});

export default function OrganisationId() {
  const [tooltipText, setTooltipText] = useState('Click to copy to clipboard');
  const { organizationId } = useSelector((state) => state.auth.user);

  const handleCopy = () => {
    setTooltipText('Copied to clipboard');
  };
  return (
    <Stack direction="column" alignItems="start" spacing={2} sx={{ my: 2 }}>
      <Typography variant="body2" textTransform={'uppercase'}>
        Organization id
      </Typography>
      <CopyToClipboard text={organizationId} onCopy={handleCopy}>
        <Tooltip title={tooltipText} placement="top">
          <StyledGenerateApiKeyBox>{organizationId || '--------------------------'}</StyledGenerateApiKeyBox>
        </Tooltip>
      </CopyToClipboard>
    </Stack>
  );
}
