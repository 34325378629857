import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ACCESS_TOKEN_KEY, getCookie } from '../store/common/apiUtils';
import { verifyToken } from '../store/auth/authSlice';

const Protected = ({ children }) => {
  const [hasChecked, setHasChecked] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    const hasToken = getCookie(ACCESS_TOKEN_KEY);
    if (!isLoggedIn && hasToken) {
      dispatch(verifyToken())
        .unwrap()
        .then(() => {
          setHasChecked(true);
        })
        .catch(() => {
          setHasChecked(true);
        });
    } else {
      setHasChecked(true);
    }
    return () => {};
  }, [dispatch, isLoggedIn]);

  if (hasChecked) {
    if (!isLoggedIn) {
      return <Navigate to="/login" replace />;
    }
    return children;
  }
  return null;
};
export default Protected;
