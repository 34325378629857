import React, { useEffect, useRef, useState } from 'react';
import {
  Alert,
  Backdrop,
  Box,
  Card,
  CircularProgress,
  IconButton,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'fecha';

import './post.css';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { togglePostsStatus, removePost } from '../../../store/post/postSlice';
import Iconify from '../../../components/Iconify';
import { getAuthHeader, SERVER } from '../../../store/common/apiUtils';
import useResponsive from '../../../hooks/useResponsive';

export default function Postcard({ _id, title, fileUrl, createdAt, status, showAdminActions, clickCount }) {
  const [content, setContent] = React.useState('');
  const [fetching, setFetching] = useState(true);
  const [isTogglingStatus, setIsTogglingStatus] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackMsg, setSnackMsg] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isDeleteing, setIsDeleteing] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const postRef = useRef(null);
  const smallDevice = useResponsive('down', 'md');

  const dispatch = useDispatch();

  useEffect(() => {
    fetch(fileUrl)
      .then((r) => r.text())
      .then((text) => {
        setFetching(false);
        setContent(text);
        setTimeout(() => {
          const imagesLen = postRef.current.getElementsByTagName('img').length;
          if (postRef.current && imagesLen > 0) {
            for (let i = 0; i < imagesLen; i += 1) {
              postRef.current.getElementsByTagName('img')[i].addEventListener('click', (event) => {
                setImageUrl(event.target.src);
                setShowImage(true);
              });
            }
          }
        }, 200);
      });
  }, []);

  const toggleStatus = () => {
    setIsTogglingStatus(true);
    setHasError(false);
    dispatch(
      togglePostsStatus({
        postId: _id,
        status: status === 'inreview' ? 'approved' : 'inreview',
      })
    )
      .unwrap()
      .then((res) => {
        setShowSnackBar(true);
        if (res.success) {
          setSnackMsg(`${status === 'inreview' ? 'Post published' : 'Post unpublished'} successfully`);
          setTimeout(() => {
            dispatch(removePost(_id));
          }, 1000);
        } else {
          setHasError(true);
          setSnackMsg(res.message || `Something went wrong`);
          setIsTogglingStatus(false);
        }
      })
      .catch(() => {
        setIsTogglingStatus(false);
        setHasError(true);
        setSnackMsg(`Something went wrong`);
      });
  };

  const deletePost = async () => {
    const ans = window.confirm('Are you sure you want to delete this post? This action cannot be undone.');
    if (ans) {
      setHasError(false);
      setIsDeleteing(true);
      try {
        const res = await axios.delete(`${SERVER}/post/${_id}`, getAuthHeader());
        setShowSnackBar(true);
        if (res?.data?.success) {
          setSnackMsg(`Post deleted successfully`);
          setTimeout(() => {
            dispatch(removePost(_id));
          }, 1000);
        } else {
          setHasError(true);
          setIsDeleteing(false);
          setSnackMsg(res?.data?.message || `Something went wrong`);
        }
      } catch (err) {
        setShowSnackBar(true);
        setHasError(true);
        setIsDeleteing(false);
        setSnackMsg(`Something went wrong`);
      }
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showImage}
        onClick={() => setShowImage(false)}
      >
        <div className="post-image-viewer">
          <img src={imageUrl} loading="lazy" alt="" />
        </div>
      </Backdrop>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showSnackBar}
        autoHideDuration={2000}
        onClose={() => setShowSnackBar(false)}
      >
        {hasError ? (
          <Alert severity="error" variant="filled">
            {snackMsg}
          </Alert>
        ) : (
          <Alert severity="success" variant="filled">
            {snackMsg}
          </Alert>
        )}
      </Snackbar>
      <Card sx={{ minHeight: 250, padding: 2, width: smallDevice ? '100%' : 475, mb: 2 }}>
        <div className="post-container" ref={postRef}>
          <Stack direction="row" justifyContent="space-between" alignItems="start" spacing={2} sx={{ mb: 1 }}>
            <Stack>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {format(new Date(createdAt), '   MMMM Do, YYYY')}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.primary' }}>
                Views: {clickCount}
              </Typography>
            </Stack>
            {showAdminActions && (
              <Box alignItems={'center'}>
                <LoadingButton
                  size="small"
                  type="submit"
                  variant="contained"
                  color={status === 'inreview' ? 'primary' : 'warning'}
                  loading={isTogglingStatus}
                  onClick={toggleStatus}
                >
                  {status === 'inreview' ? 'Publish' : 'Unpublish'}
                </LoadingButton>
                <Tooltip title="Delete">
                  <IconButton color="error" sx={{ ml: 1 }} onClick={deletePost}>
                    {isDeleteing ? <CircularProgress size={20} /> : <Iconify icon="eva:trash-2-outline" />}
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Stack>
          <h2 className="post-title">{title}</h2>
          {fetching ? (
            <Box sx={{ display: 'flex', mt: 3, justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <div className="post-body" dangerouslySetInnerHTML={{ __html: content }} />
          )}
        </div>
      </Card>
    </>
  );
}
