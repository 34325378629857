import React, { Component } from 'react';

import { Editor } from 'react-draft-wysiwyg';
import './react-draft-wsiwyg.css';
import { Card } from '@mui/material';
import axios from 'axios';

export default function CreatePostEditor({ handleUpdateContent, editorState}) {
  const uploadImageCallBack = (file) => {
    const data = new FormData(); // eslint-disable-line no-undef
    data.append('file', file);
    data.append('upload_preset', 'workupdates');
    return axios.post('https://api.cloudinary.com/v1_1/dpat2duof/upload', data);
  };
  return (
    <Card>
      <Editor
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={handleUpdateContent}
        editorState={editorState}
        toolbar={{
          options: ['link', 'emoji', 'image', 'inline', 'blockType', 'list', 'textAlign'],
          inline: { inDropdown: true },
          textAlign: { inDropdown: true },
          image: {
            urlEnabled: true,
            uploadEnabled: true,
            uploadCallback: uploadImageCallBack,
            alt: { present: true, mandatory: false },
          },
        }}
      />
    </Card>
  );
}
