import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
// import DashboardApp from './pages/DashboardApp';
import Posts from './pages/Posts';
import Protected from './hoc/Protected';
import VerifyEmail from './pages/VerifyEmail';
import ResetPassword from './pages/ResetPassword';
import CreatePost from './pages/CreatePost';
import PostsInReview from './pages/PostsInReview';
import Settings from './pages/Settings';
import Ideas from './pages/Ideas';

// ----------------------------------------------------------------------

const RenderAdminRoute = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  if (!user.isAdmin) {
    return <Navigate to="/404" />;
  }
  return <>{children}</>;
};
export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: (
        <Protected>
          <DashboardLayout />
        </Protected>
      ),
      children: [
        {
          path: 'create-post',
          element: (
            <Protected>
              <CreatePost />
            </Protected>
          ),
        },
        {
          path: 'posts',
          element: (
            <Protected>
              <Posts />
            </Protected>
          ),
        },
        {
          path: 'ideas',
          element: (
            <Protected>
              <Ideas />
            </Protected>
          ),
        },
        {
          path: 'postsinreview',
          element: (
            <RenderAdminRoute>
              <Protected>
                <PostsInReview />
              </Protected>
            </RenderAdminRoute>
          ),
        },
        {
          path: 'user',
          element: (
            <RenderAdminRoute>
              <Protected>
                <User />
              </Protected>
            </RenderAdminRoute>
          ),
        },
        {
          path: 'settings',
          element: (
            <RenderAdminRoute>
              <Protected>
                <Settings />
              </Protected>
            </RenderAdminRoute>
          ),
        },
        {
          path: 'profile-settings',
          element: (
            <Protected>
              <Posts />
            </Protected>
          ),
        },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/create-post" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'verifyemail', element: <VerifyEmail /> },
        { path: 'resetpassword', element: <ResetPassword /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
