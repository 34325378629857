import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, TextField, IconButton, InputAdornment, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../../../components/Iconify';
import { cleanForm, loginUser } from '../../../store/auth/authSlice';
import ForgetPasswordDialog from '../ForgetPassword';

// ----------------------------------------------------------------------
export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formError, formSuccess } = useSelector((state) => state.auth);

  const [showPassword, setShowPassword] = useState(false);
  const [isForgetModalOpen, setIsForgetModalOpen] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  useEffect(
    () => () => {
      dispatch(cleanForm());
    },
    []
  );

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      dispatch(loginUser(values))
        .unwrap()
        .then(() => {
          navigate('/dashboard/create-post', { replace: true });
        })
        .catch(() => {
          setSubmitting(false);
        });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setSubmitting } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleForgetPassword = () => {
    setIsForgetModalOpen((t) => !t);
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          /> */}

            <Link component={RouterLink} variant="subtitle2" to="#" underline="hover" onClick={handleForgetPassword}>
              Forgot password?
            </Link>
          </Stack>

          {formError && (
            <Alert  variant="filled"  severity="error" sx={{ my: 1 }}>
              {formError}
            </Alert>
          )}
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Login
          </LoadingButton>
          {formSuccess && (
            <Alert  variant="filled"  severity="success" sx={{ my: 1 }}>
              {formSuccess}
            </Alert>
          )}
        </Form>
      </FormikProvider>
      <ForgetPasswordDialog isOpen={isForgetModalOpen} handleClose={handleForgetPassword} />
    </>
  );
}
