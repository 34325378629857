// @mui
import { Card, Container, Stack, Typography } from '@mui/material';
import OrganizationSettings from '../sections/@dashboard/settings/organization';
// components
import Page from '../components/Page';
import GenerateApiKey from '../sections/@dashboard/settings/GenerateApiKey';
import ColorSetter from '../sections/@dashboard/settings/ColorSetter';

// ----------------------------------------------------------------------

export default function Settings() {
  return (
    <Page title="Settings">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Settings
          </Typography>
        </Stack>
        <OrganizationSettings />
        <Card sx={{ my: 2 }}>
          <GenerateApiKey />
        </Card>
        <Card sx={{ my: 2 }}>
          <ColorSetter />
        </Card>
      </Container>
    </Page>
  );
}
