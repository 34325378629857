import React, { useEffect } from 'react';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';

import { FormikProvider, Form, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { cleanForm, forgetPassword } from '../../store/auth/authSlice';


export default function ForgetPasswordDialog({ isOpen, handleClose }) {
  const dispatch = useDispatch();
  const { forgetFormError, forgetFormSuccess } = useSelector((state) => state.auth);
  useEffect(
    () => () => {
      dispatch(cleanForm());
    },
    []
  );
  const ForgetPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: ForgetPasswordSchema,
    onSubmit: () => {
      dispatch(forgetPassword(values))
        .unwrap()
        .then(() => {
          setSubmitting(false);
        })
        .catch(() => {
          setSubmitting(false);
        });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setSubmitting } = formik;
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <FormikProvider value={formik}>
        <DialogTitle>Forget Password?</DialogTitle>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogContent sx={{ mt: -1 }}>
            <DialogContentText sx={{ mb: 2 }}>Please enter your email address registered with us</DialogContentText>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            {forgetFormError && (
              <Alert  variant="filled"  severity="error" sx={{ my: 1 }}>
                {forgetFormError}
              </Alert>
            )}

            {forgetFormSuccess && (
              <Alert  variant="filled"  severity="success" sx={{ my: 1 }}>
                {forgetFormSuccess}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button size="large" onClick={handleClose} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
              Get Reset Link
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
