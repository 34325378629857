import { Alert, Grid, TextField } from '@mui/material';
import * as Yup from 'yup';
import React, { useState } from 'react';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Form, FormikProvider, useFormik } from 'formik';

import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import CreatePostPreview from './CreatePostPreview';
import CreatePostEditor from './CreatePostEditor';
import { getAuthHeader, SERVER } from '../../../store/common/apiUtils';

export default function CreateWrapper() {
  const [formError, setFormError] = useState('');
  const [formSuccess, setFormSuccess] = useState('');
  const [editorContent, setEditorContent] = useState('');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const handleUpdateContent = (content) => {
    setEditorState(content);
    setEditorContent(draftToHtml(convertToRaw(content.getCurrentContent())));
  };
  const PostTitleSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
  });
  const formik = useFormik({
    initialValues: {
      title: '',
    },
    validationSchema: PostTitleSchema,
    onSubmit: (values, { resetForm }) => {
      const postContent = editorContent.replace(/[\r\n]+/gm, '');
      const blob = new Blob([postContent]);
      const fileReader = new FileReader();
      const file = new File([blob], 'markdown.txt', { type: 'text/plain' });
      setFormError('');
      setFormSuccess('');
      fileReader.onloadend = async () => {
        const data = {
          title: values.title,
          markdown: fileReader.result,
        };
        axios
          .post(`${SERVER}/post/create`, data, getAuthHeader())
          .then((res) => {
            if (res?.data?.success) {
              setFormError('');
              setFormSuccess(res?.data?.message);
              resetForm();
              setEditorState(EditorState.createEmpty());
              setSubmitting(false);
            } else {
              setFormError(res?.data?.message);
              setSubmitting(false);
            }
          })
          .catch(() => {
            setFormError('Something went wrong. Please try again later.');
            setSubmitting(false);
          });
      };
      fileReader.readAsDataURL(file);
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setSubmitting } = formik;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <TextField
              fullWidth
              autoComplete="off"
              type="text"
              label="Post Title"
              sx={{ backgroundColor: '#fff', mb: 2 }}
              {...getFieldProps('title')}
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
            />
            <CreatePostEditor handleUpdateContent={handleUpdateContent} editorState={editorState} />
            {formError && (
              <Alert severity="error" sx={{ my: 1 }} variant="filled">
                {formError}
              </Alert>
            )}
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ my: 2 }}
            >
              Create Post
            </LoadingButton>
            {formSuccess && (
              <Alert severity="success" sx={{ my: 1 }} variant="filled">
                {formSuccess}
              </Alert>
            )}
          </Form>
        </FormikProvider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <CreatePostPreview content={editorContent} title={values.title} />
      </Grid>
    </Grid>
  );
}
