// @mui
import { Card, Container, Stack, Typography } from '@mui/material';
import IdeasList from '../sections/@dashboard/ideas/IdeasList';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';

// ----------------------------------------------------------------------

export default function Ideas() {
  return (
    <Page title="Settings">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            <Iconify icon="eva:bulb-fill"/> Ideas
          </Typography>
        </Stack>
        <IdeasList/>
      </Container>
    </Page>
  );
}
