import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Alert, Snackbar, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { getAuthHeader, SERVER } from '../../../../store/common/apiUtils';
import { setUser } from '../../../../store/auth/authSlice';

export default function OrganizationName() {
  const { organizationName, organizationId } = useSelector((state) => state.auth.user);
  const [hasError, setHasError] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const dispatch = useDispatch();
  const OrganizationNameSchema = Yup.object().shape({
    orgName: Yup.string().required('Name is required'),
  });

  const formik = useFormik({
    initialValues: {
      orgName: organizationName,
    },
    validationSchema: OrganizationNameSchema,
    onSubmit: async () => {
      setHasError('');
      const data = {
        orgName: values.orgName,
        orgId: organizationId,
      };
      try {
        const res = await axios.patch(`${SERVER}/organization/update`, data, getAuthHeader());
        if (res.data?.success) {
          dispatch(setUser({ payload: { jwtToken: res?.data?.payload?.jwtToken } }));
          setShowSuccess(true);
          setTimeout(() => {
            setShowSuccess(false);
          }, 2000);
        } else {
          setHasError(res?.data?.message || 'Something went wrong. Please retry');
          setSubmitting(false);
        }
      } catch (error) {
        setHasError('Something went wrong. Please retry');
        setSubmitting(false);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setSubmitting } = formik;
  return (
    <>
      <FormikProvider value={OrganizationNameSchema}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack direction={{ xs: 'column' }} spacing={3} sx={{ mt: 2, mb: 4 }}>
            <Typography variant="body2">ORGANIZATION NAME</Typography>
            <TextField
              autoComplete="username"
              type="text"
              {...getFieldProps('orgName')}
              error={Boolean(touched.orgName && errors.orgName)}
              helperText={touched.orgName && errors.orgName}
              size="medium"
            />
            <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
              Update Organization Name
            </LoadingButton>
            {hasError && (
              <Alert severity="error" variant="filled" sx={{ mx: 2 }}>
                {hasError}
              </Alert>
            )}
            {showSuccess && (
              <Alert severity="success" sx={{ width: '100%' }} variant="filled">
                Updated organization name successfully
              </Alert>
            )}
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
