import { Alert, Box, Divider, Stack, Toolbar, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios';
import { getAuthHeader, SERVER } from '../../../store/common/apiUtils';

const StyledGenerateApiKeyBox = styled('div')({
  backgroundColor: '#ececec',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  padding: ' 0 16px',
  borderRadius: '4px',
  fontFamily: 'monospace',
});

export default function GenerateApiKey() {
  const [apiKey, setApiKey] = useState('');
  const [generatingkey, setGeneratingkey] = useState(false);
  const [tooltipText, setTooltipText] = useState('Generate key to copy to clipboard');
  const [hasError, setHasError] = useState(false);

  useEffect(async () => {
    setGeneratingkey(true);
    try {
      const res = await axios.get(`${SERVER}/auth/apikey`, getAuthHeader());
      if (res?.data?.success && res?.data?.payload?.apiKey) {
        setApiKey(res.data.payload.apiKey);
        setGeneratingkey(false);
        setTooltipText('Click to copy to clipboard');
      }
    } catch (e) {
      setGeneratingkey(false);
      // Fail silently
    }
  }, []);

  const generateKey = () => {
    const ans = window.confirm(
      'This will generate a new API Key replacing old one.You will have to update the same in your product to receive updates. Press OK to continue.'
    );
    if (ans) {
      setGeneratingkey(true);
      setHasError('');
      axios
        .post(`${SERVER}/organization/newapikey`, {}, getAuthHeader())
        .then((res) => {
          if (res.data.success) {
            setApiKey(res.data.payload.apiKey);
            setGeneratingkey(false);
            setTooltipText('Click to copy to clipboard');
          } else {
            setGeneratingkey(false);
            setHasError(res?.data?.message || 'Something went wrong');
            setApiKey('');
          }
        })
        .catch((err) => {
          setGeneratingkey(false);
          setHasError('Something went wrong');
          setApiKey('');
        });
    }
  };
  const handleCopy = () => {
    if (apiKey) {
      setTooltipText('Copied to clipboard');
    }
  };
  return (
    <Box sx={{ my: 2, mx: 2 }}>
      <Typography variant="h6">Generate API Key</Typography>
      <Divider sx={{ mt: 1 }} />
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} alignItems={{ sm: 'end' }} sx={{ my: 2 }}>
        <Stack direction="column" alignItems="start" spacing={2}>
          <Typography variant="body2">API KEY</Typography>
          <CopyToClipboard text={apiKey} onCopy={handleCopy}>
            <Tooltip title={tooltipText} placement="top">
              <StyledGenerateApiKeyBox>{apiKey || '--------------------------'}</StyledGenerateApiKeyBox>
            </Tooltip>
          </CopyToClipboard>
        </Stack>
        <LoadingButton size="large" type="submit" variant="contained" loading={generatingkey} onClick={generateKey}>
          Generate New API Key
        </LoadingButton>
        {hasError && (
          <Alert severity="error" variant="filled" sx={{ mx: 2 }}>
            {hasError}
          </Alert>
        )}
      </Stack>
    </Box>
  );
}
