import * as Yup from 'yup';
import { Alert, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../components/Iconify';
import useQuery from '../../hooks/useQuery';
import { SERVER } from '../../store/common/apiUtils';
import { setUser } from '../../store/auth/authSlice';

export default function ResetPasswordForm() {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const query = useQuery();
  const navigate = useNavigate();
  const [resetSuccessful, setResetSuccessFull] = useState(false);
  const [error, setError] = useState('');

  const ResetSchema = Yup.object().shape({
    newPassword: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: '',
    },
    validationSchema: ResetSchema,
    onSubmit: () => {
      const token = query.get('token');
      if (token) {
        axios
          .post(`${SERVER}/auth/resetpassword`, { resetPasswordToken: token, ...values })
          .then((res) => {
            if (res?.data?.success) {
              setResetSuccessFull(true);
              dispatch(setUser({ payload: { jwtToken: res?.data?.payload?.jwtToken } }));
              setTimeout(() => {
                navigate('/dashboard/create-post', { replace: true });
              }, 2000);
            } else {
              setError(res?.data?.message || 'Something went wrong. Please retry');
              setSubmitting(false);
            }
          })
          .catch(() => {
            setError('Something went wrong. Please retry');
            setSubmitting(false);
          });
      } else {
        setError('Reset token not found');
        setSubmitting(false);
      }
    },
  });

  const { errors, touched, handleSubmit, values, isSubmitting, getFieldProps, setSubmitting } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('newPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.newPassword && errors.newPassword)}
            helperText={touched.newPassword && errors.newPassword}
          />
          {error && (
            <Alert  variant="filled"  severity="error" sx={{ my: 1 }}>
              {error}
            </Alert>
          )}
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Reset
          </LoadingButton>
          {resetSuccessful && (
            <Alert  variant="filled"  severity="success" sx={{ my: 1 }}>
              Password reset successfully. Redirecting to dashboard in 2 seconds...
            </Alert>
          )}
        </Stack>
      </Form>
    </FormikProvider>
  );
}
