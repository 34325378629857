import * as Yup from 'yup';
import React, { useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { Alert, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { getAuthHeader, SERVER } from '../../../store/common/apiUtils';
import { setUser } from '../../../store/auth/authSlice';

export default function JoinOrgForm({ handleClose }) {
  const [formError, setFormError] = useState('');
  const dispatch = useDispatch();


  const InviteIdSchema = Yup.object().shape({
    inviteId: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name required'),
  });
  const formik = useFormik({
    initialValues: {
      inviteId: '',
    },
    validationSchema: InviteIdSchema,
    onSubmit: () => {
      const data = {
        inviteid: values.inviteId,
      };
      axios
        .post(`${SERVER}/organization/verifyinvite`, data, getAuthHeader())
        .then((res) => {
          if (res?.data?.success) {
            dispatch(setUser({ payload: { jwtToken: res?.data?.payload?.jwtToken } }));
            handleClose();
          } else {
            setFormError(res?.data?.message || 'Something went wrong. Please retry');
            setSubmitting(false);
          }
        })
        .catch(() => {
          setFormError('Something went wrong. Please retry');
          setSubmitting(false);
        });
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setSubmitting } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Invitation Id"
          {...getFieldProps('inviteId')}
          error={Boolean(touched.inviteId && errors.inviteId)}
          helperText={touched.inviteId && errors.inviteId}
        />
        {formError && (
          <Alert  variant="filled"  severity="error" sx={{ my: 1 }}>
            {formError}
          </Alert>
        )}
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} sx={{ my: 2 }}>
          Join Organization
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
