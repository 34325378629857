import { combineReducers, configureStore } from '@reduxjs/toolkit';
import auth from './auth/authSlice';
import post from './post/postSlice';
import ideas from './ideas/ideaSlice';

const appReducer = combineReducers({ auth, post, ideas });

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === 'LOGOUT') state = undefined;

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
