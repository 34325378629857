import { Alert, Box, CircularProgress, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';
import { SERVER } from '../../store/common/apiUtils';

export default function VerifyEmailLoader() {
  const query = useQuery();
  const navigate = useNavigate();
  const [verifiedSuccessFully, setVerifiedSuccessFully] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const token = query.get('token');
    if (token) {
      axios
        .post(`${SERVER}/auth/verifyemail`, { emailVerificationToken: token })
        .then((res) => {
          if (res?.data?.success) {
            setVerifiedSuccessFully(true);
            setTimeout(() => {
                navigate('/login', { replace: true });
            }, 2000);
          } else {
            setError(res?.data?.message || 'Something went wrong. Please retry');
          }
        })
        .catch(() => {
          setError('Something went wrong. Please retry');
        });
    }
  }, [query]);

  if (!verifiedSuccessFully && !error) {
    return (
      <>
        <Typography sx={{ color: 'text.secondary', mb: 2 }}>Please wait while we verify your details.</Typography>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      </>
    );
  }

  if (verifiedSuccessFully) {
    return (
      <>
        <Typography sx={{ color: 'text.secondary', mb: 2}}>Voila! you are in.</Typography>
        <Alert  variant="filled"  severity="success">
          Your email has been verified successfully.{' '}
          <Link variant="subtitle2" component={RouterLink} to="/login">
            Redirecting to login page in 2 seconds
          </Link>{' '}
        </Alert>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Typography sx={{ color: 'text.secondary', mb: 2 }}>oops! Looks like our server is on coffee break </Typography>
        <Alert  variant="filled"  severity="error">{error}</Alert>
      </>
    );
  }
}
