import { Card, Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';
import Iconify from '../../../components/Iconify';
import './post.css';

export default function CreatePostPreview({ content, title }) {
  return (
    <>
      <Card sx={{ minHeight: 250, padding: 2, maxWidth: 475 }}>
        <Typography variant="button" textTransform={'uppercase'}>
          Post Preview:
        </Typography>
        <Divider sx={{ mt: 1 }} />
        <div className="post-container">
          <h2 className="post-title">{title}</h2>
          <div className="post-body" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </Card>
      <Card sx={{ minHeight: 250, padding: 2, maxWidth: 475, my: 2 }}>
        <Typography variant="button" textTransform={'uppercase'}>
          <Iconify icon="eva:bulb-outline" /> Recommendations
        </Typography>
        <Divider sx={{ mt: 1 }} />
        <List>
          <ListItem>
            <ListItemText primary="Keep image max width to 450px and keep height as auto" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Keep post short and crisp for better engagement" />
          </ListItem>
          <ListItem>
            <ListItemText primary="If the post is getting too long, add only major details and add a link to you blog to expand on released feature" />
          </ListItem>
        </List>
      </Card>
    </>
  );
}
