import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPosts } from '../../../store/post/postSlice';
import PostsViewer from './PostsViewer';

export default function PostsForReview() {
  const { isFetching, posts } = useSelector((state) => state.post);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPosts('inreview'));
  }, []);

  return <PostsViewer posts={posts} isFetching={isFetching} />;
}
