import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import OrgMaker from '../sections/post/org/OrgMaker';
import { CreateWrapper } from '../sections/@dashboard/post';

// ----------------------------------------------------------------------

export default function CreatePost() {
  const theme = useTheme();

  return (
    <Page title="Create Post">
      <OrgMaker />
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Create Post
        </Typography>
        <CreateWrapper />
      </Container>
    </Page>
  );
}
